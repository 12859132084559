var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          columns: _vm.tableList,
          pagination: _vm.pagination,
          "data-source": _vm.list,
          rowKey: function(record, index) {
            return index
          }
        },
        on: { change: _vm.changePage },
        scopedSlots: _vm._u([
          {
            key: "itemImgSlot",
            fn: function(row) {
              return _c("div", { staticClass: "itemIcon" }, [
                row.images
                  ? _c("img", {
                      staticClass: "icon-box",
                      attrs: { src: row.images.split(",")[0], alt: "" },
                      on: {
                        click: function($event) {
                          return _vm.handlePreviewImg(row.images)
                        }
                      }
                    })
                  : _c("div", { staticClass: "img-null" }, [_vm._v("暂无图片")])
              ])
            }
          },
          {
            key: "itemShamTypeSlot",
            fn: function(row) {
              return _c("div", { class: _vm.shamTypeListClass[row.shamType] }, [
                _vm._v(" " + _vm._s(_vm.shamTypeText[row.shamType]) + " ")
              ])
            }
          },
          {
            key: "itemCodeBatchMatchSlot",
            fn: function(row) {
              return _c("div", {}, [
                _vm._v(
                  " " +
                    _vm._s(row.codeBatchMatch ? row.codeBatchMatch : "-") +
                    " "
                )
              ])
            }
          },
          {
            key: "itemAdminNameSlot",
            fn: function(row) {
              return _c("div", {}, [_c("div", [_vm._v(_vm._s(row.adminName))])])
            }
          },
          {
            key: "action",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "div",
                  [
                    _c(
                      "a-button",
                      {
                        staticClass: "setup-btn mt-10",
                        attrs: { size: "small", type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.handleEvent(row, "editor")
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "mt-10" },
                  [
                    _c(
                      "a-popconfirm",
                      {
                        attrs: {
                          title: "确定删除吗？",
                          "ok-text": "Yes",
                          "cancel-text": "No"
                        },
                        on: {
                          confirm: function($event) {
                            return _vm.handleEvent(row, "delete")
                          }
                        }
                      },
                      [
                        _c(
                          "a-button",
                          {
                            staticClass: "setup-btn mt-10",
                            attrs: { size: "small", type: "danger" }
                          },
                          [_vm._v("删除")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            }
          }
        ])
      }),
      _c("ShamEditor", {
        ref: "shamEditorEl",
        on: { success: _vm.handleEditor }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }